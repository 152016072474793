<template>
  <div class="bg">
    <template v-if="list.length && !loading">
      <div class="bold font-40 title">选择要登录的商户</div>
      <div v-for="item in list" :key="item.userId" class="flex-start-center item" @click="toMenu(item)">
        <img :src="item.logo" class="icon" srcset="">
        <div>
          <p class="font-32 bold">{{ item.tenantName }}</p>
          <p class="font-28">{{ item.userName }}</p>
        </div>
        <van-icon class="arrow" name="arrow" />
      </div>
    </template>
    <empty v-if="!list.length && !loading " title="无此账号" />
  </div>
</template>

<script>
import { list } from '@/api/tenant'
import { resourceUrl } from '@/config'
import { setTenant } from '@/tool/cookie'
import { getWXCode } from '@/tool/login'
import empty from '@/components/common/empty'

export default {
  components: { empty },
  data() {
    return {
      list: [],
      tenant: {},
      loading: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 跳转菜单
    async toMenu(tenant) {
      this.tenant = tenant
      let path = '/menu'
      const { targetUrl } = this.$route.query
      if (targetUrl) path += `?targetUrl=${targetUrl}`
      await getWXCode(`${window.location.origin}${path}`)
      setTenant(this.tenant)
    },
    // 获取商户列表
    getList() {
      const { code, tenantId } = this.$route.query
      list({ code })
        .then((res) => {
          for (const item of res) {
            item.logo = `${resourceUrl}${item.logo}`
          }
          this.list = res
          this.loading = false
          if (tenantId) {
            const currMerchant = res.find(e => e.tenantId === tenantId)
            if (currMerchant) this.toMenu(currMerchant)
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.businessErrorType == 'CodeBeenUsed' || err.businessErrorType == 'CodeInvalid') {
            this.$router.push('/')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url(~@/assets/images/login/bg.png);
  background-size: 100% 304px;
  background-repeat: no-repeat;
  background-position: top center;
  .title {
    padding-top: 56px;
    margin-left: 38px;
    margin-bottom: 34px;
    .change {
      position: absolute;
      right: 36px;
      top: 45px;
    }
  }
  .item {
    width: 683px;
    height: 149px;
    background: #ffffff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    margin-left: 34px;
    margin-bottom: 28px;
    position: relative;
    .icon {
      width: 66px;
      height: 66px;
      margin-left: 22px;
      margin-right: 16px;
    }
    .arrow {
      position: absolute;
      top: 64px;
      right: 20px;
    }
  }
}
</style>
